import React, { useState } from "react";

export default function Joke(props) {
  const joke = props.joke;

  const [showPunchline, setPunchline] = useState(
    <div>
      <h3>?</h3>
    </div>
  );

  function show() {
    setPunchline(
      <div className="info-group">
        <p>- {joke.punchline}</p>
        <button onClick={hide} className="hide-button">
          Hide Joke
        </button>
      </div>
    );
  }

  function hide() {
    setPunchline(
      <div>
        <p>?</p>
      </div>
    );
  }

  return (
    <div className="joke-card">
      <div className="info-group">
        <p>{joke.setup}</p>
        <button onClick={show} className="show-button">
          Show Joke
        </button>
      </div>
      <div className="info-group"></div>
      {showPunchline}
      {/* {joke.setup && <div>joke.setup</div>}          CONDITIONAL RENDERING*/}
    </div>
  );
}
