import react, {useState, useEffect} from "react";
import "./App.css";
import Joke from "./components/Joke";
// const JokeAPI = require('sv443-joke-api');

function App() {
  const [jokes, setJokes] = useState("");

  useEffect(() => {
  fetch("https://v2.jokeapi.dev/joke/Pun,Spooky,Christmas?type=twopart&amount=5")
  .then((res) => res.json())
   .then((data) => {
     setJokes(data.jokes)
  },
  );
 }, []);



  const jokeOne = {
    setup: jokes[0]?.setup,
    punchline: jokes[0]?.delivery,
  }

  const jokeTwo = {
    setup: jokes[1]?.setup,
    punchline: jokes[1]?.delivery,
  }

  const jokeThree = {
    setup: jokes[2]?.setup,
    punchline: jokes[2]?.delivery,
  }

  const jokeFour = {
    setup: jokes[3]?.setup,
    punchline: jokes[3]?.delivery,
  }

  const jokeFive = {
    setup: jokes[4]?.setup,
    punchline: jokes[4]?.delivery,
  }

 // const jokeOne = {
  //   setup: "I got my daughter a fridge for her birthday.",
  //   punchline: "I can't wait to see her face light up when she opens it.",
  // };

  // const jokeTwo = {
  //   setup: "How did the hacker escape the police?",
  //   punchline: "He just ransomware!",
  // };

  // const jokeThree = {
  //   setup: "Why don't pirates travel on mountain roads?",
  //   punchline: "Scurvy.",
  // };

  // const jokeFour = {
  //   setup: "Why do bees stay in the hive in the winter?",
  //   punchline: "Swarm",
  // };

  // const jokeFive = {
  //   setup: "What's the best thing about Switzerland?",
  //   punchline: "I don't know, but the flag is a big plus!",
  // };

//  const getJoke = async ()  => {
//   await fetch("https://v2.jokeapi.dev/joke/Programming,Miscellaneous,Pun,Spooky,Christmas?type=twopart&amount=5")
//   .then((res) => res.json())
//    .then((data) => {
//     //  console.log(data.jokes)
//      setJokes(data.jokes)
//  } )}

  return (
    <div className="App">
      <header className="App-header">
        <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.qYNd0c_Ah8Czkz3m8Vj5kgHaHa%26pid%3DApi&f=1&ipt=c5485ea4d39d65337fb7ee9116f48c61a8e6c8a971a1e4da7c7176e9d6294d9a&ipo=images" alt="" />
        <Joke joke={jokeOne}/>
        <Joke joke={jokeTwo}/>
        <Joke joke={jokeThree}/>
        <Joke joke={jokeFour}/>
        <Joke joke={jokeFive}/>
      </header>
      {/* <button onClick={getJoke} >Click to get Joke!</button> */}
    </div>
  );
}

export default App;
